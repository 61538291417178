import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getMarkets, getSalesPersons } from '../../../utils/data';
import { convertToGMT } from '../../../utils/dateUtils';
import { removeNumCommas } from '../../../utils/numberFormatter';
import { useSnackBar } from '../../../context/snackBarContext';
import Link from '@mui/material/Link';
import { env } from '../../../env';
import {
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
} from '@mui/material';
import Block from '@mui/icons-material/Block';
import Check from '@mui/icons-material/Check';
import Dates from './FormFields/dates';
import Team from './FormFields/team';
import Weights from './FormFields/weights';
import ShearRail from './FormFields/shearRail';
import BarrierCable from './FormFields/barrierCable';
import LaborHours from './FormFields/laborHours';
import GeneralInfo from './FormFields/generalInfo';
import GearSets from './FormFields/gearSets';
import MoreOptions from './FormFields/moreOptions';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExportButton from '../../shared/exportButton';
import Checkbox from '../../shared/forms/checkbox';
import { queryClient } from '../../../App';
import UploadButton from '../../shared/uploadButton';
import { useJob } from '../../../utils/masterData';

const MainInfoForm = ({ jobInfo, canExport, setCanExport, pagePermissions }) => {
  const navigate = useNavigate();

  const [openPopup, setopenPopup] = useState(false);
  const [markets, setMarkets] = useState();
  const [salesPersons, setSalesPersons] = useState();
  const [estimatorPersons, setEstimatorPersons] = useState();

  const [formValues, setFormValues] = useState(jobInfo);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [openHoldPopup, setOpenHoldPopup] = useState(false);

  const { jobId } = useParams();
  const { setSnackbar } = useSnackBar();
  const { control, handleSubmit, setValue, reset, getValues, formState } = useForm({
    defaultValues: jobInfo,
    mode: 'onChange',
  });

  const { refetch } = useJob({ jobId });
  const { dirtyFields } = formState;
  const formSteps = ['General Information', 'Key Dates & Team Members', 'Weights & Materials', 'Labor Hours'];

  useEffect(() => {
    (async () => {
      try {
        const response = await getSalesPersons();
        setSalesPersons(response.filter((f) => f.isSalesperson));
        setEstimatorPersons(response.filter((f) => f.isEstimator));
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    getMarkets().then((obj) => setMarkets(obj));
  }, []);

  const handlePopupOpen = () => {
    setopenPopup(true);
  };

  const handleCISUploadButton = () => {
    navigate(`CIS Documents`);
  };

  function updateReactHookForm(data) {
    const keys = Object.keys(data);
    const values = Object.values(data);

    keys.forEach((key, index) => {
      if (values[index] && typeof values[index] === 'object') updateReactHookForm(values[index]);
      else setValue(keys[index], values[index]);
    });
  }

  useEffect(() => {
    updateReactHookForm(jobInfo);
  }, [jobInfo]);

  const handlePopupClose = () => {
    setopenPopup(false);
  };

  const handleExport = useCallback(async () => {
    try {
      handlePopupClose();
      const response = await axios.post(`jobs/${jobId}/export`);
      const { errors, message } = response.data;
      if (!errors.length) {
        setSnackbar({
          open: true,
          message: `${message}`,
          severity: 'success',
        });
        setCanExport(false);
      }
    } catch (error) {
      console.log(`Error occurred while exporting.`, error);
    }
  }, [jobId, setCanExport, setSnackbar]);

  const onSubmit = (formData) => {
    jobInfo = {
      ...formData,
      accessoryKit: parseInt(formData.accessoryKit ?? 0),
      bareWeight: removeNumCommas(formData.bareWeight),
      cableDiameter: parseInt(formData.cableDiameter ?? 0),
      changeOrderLF: removeNumCommas(formData.changeOrderLF),
      contractDate: formData.contractDate ? convertToGMT(new Date(formData.contractDate)) : null,
      designEngineeringHours: removeNumCommas(formData.designEngineeringHours),
      detailingHours: removeNumCommas(formData.detailingHours),
      engStamp: parseInt(formData.engStamp ?? 0),
      equipmentCalibration: removeNumCommas(formData.equipmentCalibration),
      estimatedLF: removeNumCommas(formData.estimatedLF),
      estLoads: removeNumCommas(formData.estLoads),
      fiveByEightStuds: removeNumCommas(formData.fiveByEightStuds),
      millThickness: parseInt(formData.millThickness ?? 0),
      oneByTwoStuds: removeNumCommas(formData.oneByTwoStuds),
      pocketShearMonths: removeNumCommas(formData.pocketShearMonths),
      pocketShearSets: removeNumCommas(formData.pocketShearSets),
      projectEngineeringHours: removeNumCommas(formData.projectEngineeringHours),
      projectId: jobInfo.projectId,
      projectManagementHours: removeNumCommas(formData.projectManagementHours),
      rails: removeNumCommas(formData.rails),
      shippingWeight: removeNumCommas(formData.shippingWeight),
      strandCost: removeNumCommas(formData.strandCost),
      stressGearMonths: removeNumCommas(formData.stressGearMonths),
      stressGearSets: removeNumCommas(formData.stressGearSets),
      threeByEightStuds: removeNumCommas(formData.threeByEightStuds),
      threeByFourStuds: removeNumCommas(formData.threeByFourStuds),
      jobType: { ...formData.jobType, id: !formData.jobType?.id ? 0 : formData.jobType.id },
      areaCode: formData?.areaCode,
    };

    Object.keys(dirtyFields).forEach((item) => {
      setValue(item, jobInfo[item], { shouldDirty: false });
    });

    axios
      .patch(`/${jobInfo.projectId}?area=maininfo`, jobInfo)
      .then((response) => {
        reset(jobInfo);
        setFormValues(jobInfo);
        setSnackbar({
          open: true,
          message: 'Job updated successfully',
          severity: 'success',
        });

        //* make key invalidate, marking [job, jobId] keys as stale.
        queryClient.invalidateQueries({ queryKey: ['jobs/jobId', jobId] });
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: 'Could not save job information.',
          severity: 'error',
        });
      });
  };

  const resetForm = () => {
    reset(formValues);
  };

  const totalSteps = () => {
    return formSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      isLastStep() && !allStepsCompleted() ? formSteps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleHold = () => {
    setOpenHoldPopup(true);
  };

  const handleHoldJob = async () => {
    try {
      const endpoint = jobInfo?.jobOnHold ? `Jobs/${jobId}/unhold` : `Jobs/${jobId}/hold`;

      // Perform the API request to hold or unhold the job
      await axios.put(endpoint);

      // Close the hold popup
      setOpenHoldPopup(false);

      // Refetch job data to reflect the updated state
      await refetch();
    } catch (error) {
      setOpenHoldPopup(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginTop: '2rem', width: '100%' }}>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {formSteps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)} disableRipple>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Stack
            sx={{
              margin: { xs: '2rem 0', sm: '2.5rem auto' },
              width: { sm: '90%', xl: '75%' },
            }}
          >
            {allStepsCompleted() ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h1">
                  {formSteps[activeStep]} ({jobInfo?.name})
                  <span style={{ color: 'red', paddingLeft: '45px', fontSize: '20px' }}>
                    {jobInfo?.jobOnHold ? <Chip icon={<Block />} color="warning" label="This Job is on Hold" /> : ''}
                  </span>
                </Typography>

                <Box sx={{ marginTop: '2rem' }}>
                  {activeStep === 0 && (
                    <Grid container columnSpacing={{ xs: 2, md: 6, xl: 7 }} rowSpacing={{ xs: 3, md: 4 }}>
                      <Grid item xs={12} lg={8} xl={7}>
                        <GeneralInfo
                          pagePermissions={pagePermissions}
                          control={control}
                          getValues={getValues}
                          markets={markets}
                        />
                        <GearSets pagePermissions={pagePermissions} control={control} />
                      </Grid>
                      <Grid item xs={12} lg={4} xl={5}>
                        <MoreOptions pagePermissions={pagePermissions} control={control} jobInfo={jobInfo} />
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid container columnSpacing={{ xs: 2, md: 5 }} rowSpacing={{ xs: 3, md: 0 }}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h2">Dates</Typography>
                        <Dates pagePermissions={pagePermissions} control={control} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h2">Team</Typography>
                        <Team
                          pagePermissions={pagePermissions}
                          control={control}
                          salesPersons={salesPersons}
                          estimatorPersons={estimatorPersons}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <Grid container columnSpacing={{ xs: 4, md: 5 }} rowSpacing={{ xs: 4 }}>
                      <Grid item xs={12} sm={6} xl={3}>
                        <Typography variant="h2">Weights</Typography>
                        <Weights pagePermissions={pagePermissions} control={control} setValue={setValue} />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={3}>
                        <Typography variant="h2">Shear Rail</Typography>
                        <ShearRail pagePermissions={pagePermissions} control={control} />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={3}>
                        <Typography variant="h2">Barrier Cable</Typography>
                        <BarrierCable pagePermissions={pagePermissions} control={control} />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={3}>
                        <Typography variant="h2" sx={{ marginBottom: '.6rem' }}>
                          Additional Options
                        </Typography>
                        <Box>
                          <Checkbox
                            isDisabled={!pagePermissions.canEdit}
                            control={control}
                            label="PT Cable"
                            name="hasPTCable"
                          />
                        </Box>
                        <Box>
                          <Checkbox
                            isDisabled={!pagePermissions.canEdit}
                            label="PT Accessories"
                            name="hasAccessories"
                            control={control}
                          />
                        </Box>
                        <Box>
                          <Checkbox
                            isDisabled={!pagePermissions.canEdit}
                            label="PT Restoration"
                            name="hasRestoration"
                            control={control}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 3 && (
                    <Grid container columnSpacing={{ xs: 2, md: 5 }} rowSpacing={{ xs: 3, md: 0 }}>
                      <Grid item xs={12} md={6}>
                        <LaborHours pagePermissions={pagePermissions} control={control} />
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4 }}>
                  <Button
                    className="btn-step btn-prev"
                    disabled={activeStep === 0}
                    disableRipple
                    onClick={handleBack}
                    startIcon={<NavigateBeforeIcon />}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    className="btn-step btn-next"
                    disabled={activeStep === formSteps.length - 1}
                    disableRipple
                    endIcon={<NavigateNextIcon />}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
          </Stack>
          <ActionBar>
            <Grid container>
              <Grid item xs={6}>
                <Link href={env.REACT_APP_CLASSIC_HOME + `Project/${jobId}/JobInfo`} underline="hover">
                  {'Switch To Classic'}
                </Link>
              </Grid>

              <Grid item xs={6}>
                {pagePermissions.canUseHoldBtn ? (
                  <Button
                    className="btn btn-icon"
                    onClick={handleHold}
                    startIcon={jobInfo?.jobOnHold ? <Check /> : <Block />}
                  >
                    {jobInfo?.jobOnHold ? 'UnHold' : 'Hold'}
                  </Button>
                ) : null}
                <UploadButton disabled={!pagePermissions.canEdit} onClick={handleCISUploadButton} />

                <ExportButton
                  onClick={handlePopupOpen}
                  disabled={!pagePermissions.canExport || Object.keys(dirtyFields).length ? true : !canExport}
                />
                <ActionSubmitButton2
                  buttonText={'Save'}
                  disabled={!pagePermissions.canEdit || !(Object.keys(dirtyFields).length ?? false)}
                />
                <ActionCancelButton
                  buttonText={'Cancel'}
                  disabled={!(Object.keys(dirtyFields).length ?? false)}
                  clickHandler={resetForm}
                />
              </Grid>
            </Grid>
          </ActionBar>
        </Box>
      </form>

      <Dialog
        open={openPopup}
        onClose={handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6">Are you sure you want to export this job to SAP?</Typography>
          <Typography variant="subtitle2">&nbsp;</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleExport} autoFocus>
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={handlePopupClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openHoldPopup}
        onClose={() => {
          setOpenHoldPopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6">
            Are you sure you want to {`${jobInfo?.jobOnHold ? 'unhold' : 'hold'}`} the job?
          </Typography>
          <Typography variant="subtitle2">&nbsp;</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={async () => {
              await handleHoldJob();
            }}
            sx={{ px: 4, border: 'solid 1px', width: '100px' }}
          >
            Yes
          </Button>
          <Button
            color="error"
            onClick={() => {
              setOpenHoldPopup(false);
            }}
            sx={{ px: 4, border: 'solid 1px' }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MainInfoForm;
