import { Box, Button, Grid } from '@mui/material';
import {
  Column,
  DataGrid,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule,
  Button as DevextremButton,
  CustomRule,
} from 'devextreme-react/data-grid';
import { FaFileImport } from 'react-icons/fa6';
import { IoIosAdd } from 'react-icons/io';
import { useState, useCallback, useRef, useEffect } from 'react';
import ActionBar from '../../../components/shared/actionBar';
import { ActionSubmitButton2 } from '../../../components/shared/actionSubmitButton';
import ActionCancelButton from '../../../components/shared/actionCancelButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackBar } from '../../../context/snackBarContext';
import { matchPath, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useBidItems, useExcuseCodes } from '../../../utils/masterData';
import axios from 'axios';
import { ROUTE_JOBS_PTQUANTITIES, ROUTE_LOAD_IMPORT_BARRIER_CABLE_STEEL_POST } from '../../../routes';
import { queryClient } from '../../../App';
import { PartNumberLookupDropdown } from '../../shared/dataGrid/partNumberLookupDropdown';
import { DropDownOptions } from 'devextreme-react/lookup';

export const AccessoriesForm = ({ pagePermissions }) => {
  const [hasEditData, setHasEditData] = useState(false);
  const { jobId } = useParams();
  const looseComponentsdataGridRef = useRef();
  const [looseComponentsData, setLooseComponentsData] = useState();
  const { setSnackbar } = useSnackBar();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const loadId = searchParams.get('loadId');
  const accessoriesBidItem = ['PT ACCESSORIES', 'PT CABLE'];
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const hiddenFileInput = useRef(null);
  const { data: excuseCodesOptions } = useExcuseCodes();
  const { data: bidItemsLookupData } = useBidItems();
  const isBarrierCableAddOns = !!matchPath(
    `/pt/jobs/${ROUTE_JOBS_PTQUANTITIES}/${ROUTE_LOAD_IMPORT_BARRIER_CABLE_STEEL_POST}`,
    decodeURIComponent(pathname)
  );

  //2 --PT Hardware,3 -- Accessories, 8 -- Stressing, 9 --Support Steel
  const { data: partMaterialsLookUp } = useQuery({
    queryKey: ['PartMaterials', { categoryIds: isBarrierCableAddOns ? [3, 9] : [3, 2] }],
    queryFn: async () =>
      (
        await axios.get(`PT/Parts/PT/PartsbyCategory`, {
          loaderRequired: false,
          params: { categoryIds: isBarrierCableAddOns ? [3, 9] : [3, 2, 8] },
          paramsSerializer: { indexes: null },
        })
      ).data,
  });

  const fetchLooseComponentsData = useCallback(async () => {
    const response = await axios.get(`jobs/${jobId}/loads/${loadId}/CableAccessories`, {
      params: { type: isBarrierCableAddOns ? 'barriercable' : 'accessories' },
    });
    setLooseComponentsData(response.data?.value);
  }, [jobId, loadId, isBarrierCableAddOns]);

  useEffect(() => {
    (async () => {
      try {
        await fetchLooseComponentsData();
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error fetching Cable Accessories',
          severity: 'error',
        });
      }
    })();
  }, [setSnackbar, fetchLooseComponentsData]);

  const updateLooseComponents = useMutation({
    mutationFn: (loosecompData) =>
      axios
        .put(
          `jobs/${jobId}/loads/${loadId}/CableAccessories?type=${
            isBarrierCableAddOns ? 'barrierCable' : 'accessories'
          }`,
          loosecompData,
          { loaderRequired: false }
        )
        .then(async () => {
          await fetchLooseComponentsData();
        }),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: ['updateLooseComponents', jobId, loadId] }),
  });

  //* Handle batch edit process request
  const processBatchRequest = useCallback(
    async (loosecompData, component) => {
      try {
        await updateLooseComponents.mutateAsync(loosecompData);
        setSnackbar({ open: true, message: 'Saved data successfully', severity: 'success' });
        component.cancelEditData();
      } catch (error) {
        setSnackbar({ open: true, message: 'Error saving data', severity: 'error' });
      }
    },
    [setSnackbar, updateLooseComponents]
  );

  //* disable update for installType and partId(SAP Materaial)
  const onEditorPreparing = useCallback(
    (event) => {
      if (event.dataField === 'bidItemId' && !isBarrierCableAddOns) {
        event.editorOptions.dataSource = bidItemsLookupData.filter((item) =>
          accessoriesBidItem.includes(item.description)
        );
      }
      // for barrier cable.
      if (event.dataField === 'bidItemId' || event.dataField === 'weight' || event.dataField === 'exCode') {
        event.editorOptions.readOnly = false;
        return;
      }

      // disable for not having new rows.
      if (!event.row.isNewRow && event.row.data.status !== 1) {
        event.editorOptions.readOnly = true;
      } else if (event.row.isNewRow && (event.dataField === 'materialDes' || event.dataField === 'quantity')) {
        event.editorOptions.readOnly = false;
      } else if (event.dataField === 'quantity' || event.dataField === 'exCode') {
        event.editorOptions.readOnly = false;
      } else {
        event.editorOptions.readOnly = true;
      }
    },
    [isBarrierCableAddOns, bidItemsLookupData, accessoriesBidItem]
  );

  const onSaving = useCallback(
    (event) => {
      event.cancel = true;
      let payload = {
        looseComponentstoUpdate: [],
        looseComponentstoRemove: [],
      };
      //* generate the payload
      if (event.changes.length) {
        let allRecords = looseComponentsdataGridRef.current.instance.getVisibleRows();
        event.changes.forEach((change) => {
          if (change.type === 'remove') payload.looseComponentstoRemove.push(change.key);
          else if (change.type === 'insert') {
            payload.looseComponentstoUpdate.push(change.data);
          } else {
            // If this is update, get other fields as well
            let row = allRecords.find(
              (r) => r.data?.cutId === change.key?.cutId && r.data?.materialDes === change.key?.materialDes
            );
            if (row?.data && isBarrierCableAddOns) {
              row.data.bidItem = bidItemsLookupData.find((item) => item.id === change.data.bidItemId)?.description;
            }
            payload.looseComponentstoUpdate.push({
              ...row?.data,
            });
          }
        });
        //* handle async request
        event.promise = processBatchRequest(payload, event.component);
      }
    },
    [processBatchRequest, isBarrierCableAddOns, bidItemsLookupData]
  );

  function setStateValue(rowData, value) {
    rowData.sapMaterial = partMaterialsLookUp.find((x) => x.matDescription === value).materialId;
    rowData.partId = partMaterialsLookUp.find((x) => x.matDescription === value).partId;
    if (!isBarrierCableAddOns) {
      const categoryId = partMaterialsLookUp.find((x) => x.matDescription === value).categoryId;
      rowData.bidItemId = categoryId === 2 ? 1 : 4;
    }
    this.defaultSetCellValue(rowData, value);
  }

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('file', fileUploaded, fileUploaded.name);
    try {
      await axios
        .post(
          `jobs/${jobId}/loads/${loadId}/ImportLooseComponents?type=${
            isBarrierCableAddOns ? 'barrierCable' : 'accessories'
          }`,
          formData
        )
        .then(async () => {
          await fetchLooseComponentsData();
        });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error importing Cable Accessories',
        severity: 'error',
      });
    }
  };

  const deleteButtonVisible = useCallback((rowProps) => {
    return rowProps.row.data?.status === 1;
  }, []);

  const onInitNewRow = useCallback(
    (e) => {
      e.data.exCode = 1;
      if (!isBarrierCableAddOns) return;
      e.data.bidItemId = 2;
    },
    [isBarrierCableAddOns]
  );

  const validation = (options) => {
    //*  quantity and weight to validation
    if (options?.value) return options?.value > 0;
    else return true;
  };

  const renderPartNumberLookupDropdown = useCallback(
    (props) => (
      <PartNumberLookupDropdown
        searchEnabled
        searchExpr={['matDescription', 'partNumber', 'materialId']}
        displayExpr={'matDescription'}
        valueExpr="matDescription"
        {...props}
      >
        <DropDownOptions height={600} minHeight={300} position={'left'} minWidth={300} />
      </PartNumberLookupDropdown>
    ),
    []
  );

  return (
    <Grid container spacing={2} my={2}>
      <Grid item>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Button
            sx={{ px: 20 }}
            onClick={() => looseComponentsdataGridRef.current.instance.addRow()}
            className="btn"
            startIcon={<IoIosAdd />}
            disabled={!pagePermissions.canEdit}
          >
            New
          </Button>
          <Button
            className="btn"
            disabled={!pagePermissions.canEdit}
            startIcon={<FaFileImport size={20} />}
            onClick={handleClick}
          >
            Import
          </Button>
          <input
            type="file"
            accept=".csv, .xlsx,.xls"
            onChange={handleChange}
            ref={hiddenFileInput}
            style={{ display: 'none' }} // Make the file input element invisible
          />
        </Box>
      </Grid>
      <Grid item sx={{ mb: 8 }}>
        <DataGrid
          ref={looseComponentsdataGridRef}
          id="gridContainer"
          className="datagrid-container"
          dataSource={looseComponentsData}
          onSaving={onSaving}
          onInitNewRow={onInitNewRow}
          height={800}
          onToolbarPreparing={(e) => e.toolbarOptions.items.splice(0)}
          onContentReady={(e) => setHasEditData(e.component.hasEditData())}
          onEditorPreparing={onEditorPreparing}
        >
          <Scrolling mode="infinite" />
          <Editing
            mode="batch"
            allowUpdating={pagePermissions.canEdit}
            allowAdding={pagePermissions.canEdit}
            allowDeleting={pagePermissions.canEdit}
            allowEditing={pagePermissions.canEdit}
            selectTextOnEditStart={true}
          />
          <Column
            dataField="materialDes"
            caption="Description"
            setCellValue={setStateValue}
            editCellComponent={(props) => renderPartNumberLookupDropdown(props)}
            width={400}
          >
            <Lookup dataSource={partMaterialsLookUp} valueExpr={'matDescription'} displayExpr={'matDescription'} />
            <RequiredRule />
          </Column>
          <Column dataField="sapMaterial" caption="SAP Material" />
          <Column dataField="quantity" caption="Quantity" dataType="number" alignment="left">
            <RequiredRule />
            <CustomRule validationCallback={validation} message="Value cannot be negative" />
          </Column>
          {isBarrierCableAddOns && (
            <Column dataField="weight" caption="Weight" dataType="number" alignment="left">
              <CustomRule validationCallback={validation} message="Value cannot be negative" />
            </Column>
          )}
          <Column dataField="release" caption="Release" allowEditing={false} />
          <Column dataField="bidItemId" caption="Bid Item">
            <Lookup dataSource={bidItemsLookupData} valueExpr={'id'} displayExpr={'description'} />
          </Column>

          <Column dataField="exCode" caption="Excuse Code">
            <Lookup dataSource={excuseCodesOptions?.value} valueExpr={'exCode'} displayExpr={'description'} />
          </Column>
          <Column type="buttons">
            <DevextremButton name="delete" disabled={!pagePermissions.canEdit} visible={deleteButtonVisible} />
          </Column>
        </DataGrid>
      </Grid>
      <ActionBar sx={{ justifyContent: 'end', display: 'flex', gap: '10px' }}>
        <ActionSubmitButton2
          buttonText={'Save'}
          onClick={() => looseComponentsdataGridRef.current.instance.saveEditData()}
          disabled={!hasEditData || !pagePermissions.canEdit}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData}
          clickHandler={() => looseComponentsdataGridRef.current.instance.cancelEditData()}
        />
      </ActionBar>
    </Grid>
  );
};
