import { Helmet } from 'react-helmet-async';
import { useParams, useSearchParams } from 'react-router-dom';
import { StudRailChairsForm } from '../../../components/PT/StudRail/studRailChairsForm';
import { Box, Typography } from '@mui/material';
import { useJob } from '../../../utils/masterData';
import PageLoader from '../../../components/shared/pageLoader';

export default function StudRailQuantitiesChairs() {
  const [searchParams] = useSearchParams();
  const { loadDescription, jobId } = useParams();
  const jobName = searchParams.get('jn'); //job name
  const { data: jobData, isLoading, error } = useJob({ jobId });

  const pagePermissions = {
    canEdit: !jobData?.value?.jobOnHold,
  };

  if (isLoading) return <PageLoader />;
  if (error) return <div>Error loading project info</div>;

  return (
    <>
      <Helmet>
        <title>Stud Rail Accessories</title>
      </Helmet>
      <Typography variant="h1">
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h1">{decodeURIComponent(jobName)}</Typography>
          <Typography variant="h1">{loadDescription}</Typography>
        </Box>
      </Typography>
      <StudRailChairsForm pagePermissions={pagePermissions} />
    </>
  );
}
