import { Box, Button, Grid } from '@mui/material';
import {
  Column,
  DataGrid,
  Scrolling,
  Editing,
  Lookup,
  RequiredRule,
  CustomRule,
  Button as DevextremButton,
} from 'devextreme-react/data-grid';
import { IoIosAdd } from 'react-icons/io';
import { useState, useCallback, useRef, useEffect } from 'react';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import { useQuery } from '@tanstack/react-query';
import { useSnackBar } from '../../../context/snackBarContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { useBidItems, useExcuseCodes } from '../../../utils/masterData';
import axios from 'axios';
import { PartNumberLookupDropdown } from '../../shared/dataGrid/partNumberLookupDropdown';
import { BID_ITEMS, CUT_STAUTS } from '../../../common/constants';
import { DropDownOptions } from 'devextreme-react/lookup';

export const StudRailChairsForm = ({ pagePermissions }) => {
  const studRailsChairsDataGridRef = useRef();
  const { jobId } = useParams();
  const { setSnackbar } = useSnackBar();
  const [searchParams] = useSearchParams();
  const loadId = searchParams.get('loadId');

  const { data: excuseCodesOptions } = useExcuseCodes();
  const { data: bidItemsLookupData } = useBidItems();
  const { data: chairLookUpData } = useQuery({
    queryKey: ['StudRailChairParts'],
    queryFn: async () => (await axios.get('PT/Parts/StudRailChairParts', { loaderRequired: false })).data,
    refetchIntervalInBackground: false,
  });

  const [studRailChairsData, setStudRailChairsData] = useState();
  const [hasEditData, setHasEditData] = useState(false);

  const fetchStudRailChairsData = useCallback(async () => {
    const response = await axios.get(`jobs/${jobId}/loads/${loadId}/StudRailChairs`);
    setStudRailChairsData(response.data?.value);
  }, [jobId, loadId]);

  useEffect(() => {
    (async () => {
      try {
        await fetchStudRailChairsData();
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error fetching chairs data',
          severity: 'error',
        });
      }
    })();
  }, [fetchStudRailChairsData]);

  const updateStudRailChairs = useCallback(
    async (chairsData) => {
      await axios.put(`jobs/${jobId}/loads/${loadId}/StudRailChairs`, chairsData, { loaderRequired: false });
      await fetchStudRailChairsData();
    },
    [fetchStudRailChairsData]
  );

  //* Handle batch edit process request
  const processBatchRequest = useCallback(
    async (chairsData, component) => {
      try {
        await updateStudRailChairs(chairsData);
        setSnackbar({ open: true, message: 'Saved data successfully', severity: 'success' });
        component.cancelEditData();
      } catch (error) {
        setSnackbar({ open: true, message: 'Error saving data', severity: 'error' });
      }
    },
    [setSnackbar, updateStudRailChairs]
  );

  //* disable edit for cuts when any of the following conditions matches.
  const onEditorPreparing = useCallback((e) => {
    const isSC = /^SC\d+/.test(e.row.data.release);

    //* if not draft status and not a new row
    if (e.row.data?.status !== CUT_STAUTS.draft && !e.row.isNewRow) e.editorOptions.readOnly = true;

    //* not sc cut and is not a new row
    if (!isSC && !e.row.isNewRow) e.editorOptions.disabled = true;

    if (e.dataField === 'materialDes' && !e.row.isNewRow) e.editorOptions.disabled = true;
  }, []);

  const onSaving = useCallback(
    (event) => {
      event.cancel = true;
      let payload = {
        chairs: [],
        chairstoRemove: [],
      };
      //* generate the payload
      if (event.changes.length) {
        let allRecords = studRailsChairsDataGridRef.current.instance.getVisibleRows();
        event.changes.forEach((change) => {
          if (change.type === 'remove') payload.chairstoRemove.push(change.key);
          else if (change.type === 'insert') {
            payload.chairs.push(change.data);
          } else {
            //* If this is update, get other fields as well for update
            //* composite key cutid  and materialdesc
            let row = allRecords.find(
              (r) => r.data?.cutId === change.key?.cutId && r.data?.materialDes === change.key?.materialDes
            );

            payload.chairs.push({
              ...row?.data,
            });
          }
        });
        //* handle async request
        event.promise = processBatchRequest(payload, event.component);
      }
    },
    [processBatchRequest, bidItemsLookupData]
  );

  const deleteButtonVisible = useCallback((rowProps) => {
    return /^SC\d+/.test(rowProps.row.data?.release) && rowProps.row.data?.status === CUT_STAUTS.draft;
  }, []);

  const onInitNewRow = useCallback((e) => {
    e.data.exCode = 1;
    e.data.bidItemId = BID_ITEMS.studRails;
  }, []);

  const validation = (options) => {
    //*  quantity  validation
    if (options?.value) return options?.value > 0;
    else return true;
  };

  function setStateValue(rowData, value) {
    rowData.sapMaterial = chairLookUpData.find((x) => x.matDescription === value).materialId;
    rowData.partId = chairLookUpData.find((x) => x.matDescription === value).partId;
    this.defaultSetCellValue(rowData, value);
  }

  const renderPartNumberLookupDropdown = useCallback((props) => {
    const isDisabled = !props.data.row?.isNewRow;
    return (
      <PartNumberLookupDropdown
        disabled={isDisabled}
        searchEnabled
        searchExpr={['matDescription', 'partNumber', 'materialId']}
        displayExpr={'matDescription'}
        valueExpr="matDescription"
        {...props}
      >
        <DropDownOptions height={600} minHeight={300} position={'left'} minWidth={300} />
      </PartNumberLookupDropdown>
    );
  }, []);

  return (
    <Grid container spacing={2} my={2}>
      <Grid item>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Button
            sx={{ px: 20 }}
            onClick={() => studRailsChairsDataGridRef.current.instance.addRow()}
            className="btn"
            startIcon={<IoIosAdd />}
            disabled={!pagePermissions.canEdit}
          >
            New
          </Button>
        </Box>
      </Grid>
      <Grid item sx={{ marginBottom: 8 }}>
        <DataGrid
          ref={studRailsChairsDataGridRef}
          dataSource={studRailChairsData}
          onSaving={onSaving}
          onInitNewRow={onInitNewRow}
          onToolbarPreparing={(e) => e.toolbarOptions.items.splice(0)}
          onContentReady={(e) => setHasEditData(e.component.hasEditData())}
          onEditorPreparing={onEditorPreparing}
        >
          <Scrolling mode="infinite" />
          <Editing
            mode="batch"
            allowUpdating={pagePermissions.canEdit}
            allowAdding={pagePermissions.canEdit}
            allowDeleting={pagePermissions.canEdit}
            allowEditing={pagePermissions.canEdit}
          />
          <Column
            dataField="materialDes"
            caption="Description"
            setCellValue={setStateValue}
            editCellComponent={(props) => renderPartNumberLookupDropdown(props)}
            width={400}
          >
            <Lookup dataSource={chairLookUpData} valueExpr={'matDescription'} displayExpr={'matDescription'} />
            <RequiredRule />
          </Column>
          <Column dataField="sapMaterial" caption="SAP Material" allowEditing={false} />
          <Column dataField="quantity" caption="Quantity" dataType="number" alignment="left">
            <RequiredRule />
            <CustomRule validationCallback={validation} message="Value cannot be negative" />
          </Column>
          <Column dataField="release" caption="Release" allowEditing={false} />
          <Column dataField="bidItemId" caption="Bid Item">
            <Lookup dataSource={bidItemsLookupData} valueExpr={'id'} displayExpr={'description'} />
          </Column>
          <Column dataField="exCode" caption="Excuse Code">
            <Lookup dataSource={excuseCodesOptions?.value} valueExpr={'exCode'} displayExpr={'description'} />
          </Column>
          <Column type="buttons">
            <DevextremButton name="delete" visible={deleteButtonVisible} />
          </Column>
        </DataGrid>
      </Grid>
      <ActionBar sx={{ justifyContent: 'end', display: 'flex', gap: '10px' }}>
        <ActionSubmitButton2
          buttonText={'Save'}
          onClick={() => studRailsChairsDataGridRef.current.instance.saveEditData()}
          disabled={!hasEditData}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData}
          clickHandler={() => studRailsChairsDataGridRef.current.instance.cancelEditData()}
        />
      </ActionBar>
    </Grid>
  );
};
